@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');


/* Hide spinners for Chrome, Safari, Edge, and Opera for xpts-input */
.xpts-input::-webkit-outer-spin-button,
.xpts-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners for Firefox for xpts-input */
.xpts-input {
  -moz-appearance: textfield;
}


@media (max-width: 450px) {
  .entry-card {
    min-width: 100% !important;

  }
  .player-single{
    max-width: 60px !important;
  }
  .content-wrapper {
    padding: 10px !important;
  }

  .personal .content-wrapper{
    padding: 0 !important;
    border: none;
  }

  .monthly-wrapper {
    padding: 30px 0 !important;
    border-top: 1px solid #dadada !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  .monthly-wrapper:first-of-type{
    border-radius: 0 !important;
  }

  .global-card {
    min-width: 100% !important;
  }
  .live-header h4 {
    font-size: 16px !important;
  }
  .live-header .shield {
    max-width: 25px !important;
  }
  .show-desktop {
    display:none;
  }
  .chart-buttons {
    width: 100%;
  }

  .formBox {
    border: none !important;
    padding: 10px !important;
  }

  h1 {
    font-size: 32px !important;
    font-weight: 600;
  }
  
  h2 {
    font-size: 24px !important;
    font-weight: 600;
  }
  
  h3 {
    font-size: 20px !important;
    font-weight: 500;
  }
  
  h4 {
    font-size: 18px !important;
    font-weight: 600;
  }

  .history-menu select{
    padding: 10px;
    margin-left: 0px !important;
    margin-top: 5px;
  }
}

@media (min-width: 449px) {
  .show-mobile {
    display: none;
  }

}

@media (max-width: 1000px) {
  .body {
    flex-direction: column !important;
    overflow-y: auto !important;
    max-height: 100% !important;
  }

  .content {
    margin-top:70px;
  }

  .navigation {
    width: 100%;
    z-index: 3;
  }

  .mobile-nav_header, .mobile-nav_body {
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid #dadada;
  }

  .planner-entry {
    display: block;
    margin-top: 5px;
  }

  .chart {
    height: 70vh;
  }

  .logo {
    max-width: 100px !important;
  }

  .menuWrapper {
    margin-top: 10px;
  }

}

@media (min-width: 1000px) {
  .menuWrapper {
    margin-top: 15px;
  }}

[onclick], .onclick {
  cursor: pointer !important;
}

.content * {
  transition: opacity 0.3s, transform 0.3s;
}

.body { 
  max-height: 100vh;
  overflow-y: scroll;

}
body, p, h1, h2, h3, h4, input, button, select, input, select::before, .MuiTableContainer-root td, .MuiInputBase-root, .MuiButtonBase-root, input[id=":rth:"] , .MuiTableContainer-root div, .MuiToolbar-root p
{
  font-family: 'Montserrat', sans-serif !important;
  margin-top: 0;
  color: #262626;
 
}

p {
  font-size: 16px;
  font-weight: 300;
}

h1 {
  font-size: 38px;
  font-weight: 600;

}

h2 {
  font-size: 28px;
  font-weight: 600;

}

h3 {
  font-size: 24px;
  font-weight: 500;

}

h4 {
  font-size: 22px;
  font-weight: 600;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.block {
  display: block;
}

.red {
  color: #a00000;
}

.green {
  color: #08a600;
}

.grey {
  color:#dadada;
}
.white{
  color: #fff;
}

.black-bg{
  background-color: #262626 ;
}

.grey-bg {
  background-color: #FAFAFA;
}

.white-bg{
  background-color: #fff ;
}

.fdr-8 {
  background: #56CD8B;
}

.fdr-7 {
  background: #87D275;
}

.fdr-6 {
  background: #B5D26D;
}

.fdr-5 {
  background: #D3E03C;
}

.fdr-4 {
  background: #FDE217;
}

.fdr-3 {
  background: #FFC825;
}

.fdr-2 {
  background: #FF8641;
}

.fdr-1 {
  background: #F23E4A;
}

.black-border {
  border:  0.5px solid #262626 ;
}

.grey-border {
  border:  1px solid #dadada ;
}

.purple-border {
  border:  1px solid #5e48e0 ;
}

.no-border {
  border:none;
}

.mt-5 {margin-top: 5px;} .mt-10 {margin-top: 10px;} .mt-20 {margin-top: 20px;} .mt-30 {margin-top: 30px;} .mt-60 {margin-top: 60px;}

.mb-0 {margin-bottom: 0px;} .mb-5 {margin-bottom: 5px;} .mb-10 {margin-bottom: 10px;} .mb-20 {margin-bottom: 20px;} .mb-30 {margin-bottom: 30px;} .mb-60 {margin-bottom: 60px;}

.ml-5 {margin-left: 5px;} .ml-10 {margin-left: 10px;} .ml-15 {margin-left: 15px;} .ml-20 {margin-left: 20px;} .ml-30 {margin-left: 30px;} .ml-60 {margin-left: 60px;}

.pl-5 {padding-left: 5px;} .pl-10 {padding-left: 10px;} .pl-20 {padding-left: 20px;} .pl-30 {padding-left: 30px;} .pl-60 {padding-left: 60px;}

.p-5 {padding:5px;} .p-10 {padding:10px;} .p-20 {padding:20px;} .p-30 {padding:30px;}

.border-white {
  border: 1px solid white;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y {
overflow-y: scroll;
}

.no-transform {
  transform: translateY(0px);
}

.transformYthree{
  transform: translateY(3px);
}

.fullwidth {
  width: 100%;
}

.material-symbols-outlined {
  transform: translateY(4px);
  margin-right: 10px;
}

.filter .material-symbols-outlined {
  transform: translateX(-3px);
  margin: 0;
  padding: 0;
}

.body {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: stretch;
}

.live-header h4 {
  font-size: 18px;
}

.navigation {
  flex: none;
  position: sticky;
  top: 0;
}

.mobile-nav {
  background-color: #fff;
  z-index: 3;
  position: fixed;
  top: 0;
  width: 100%;
}

.content {
  width: 100%;
  box-sizing: border-box;
}

.content-wrapper {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #dadada;
}

.monthly-wrapper {
  padding: 20px;
  border-top: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  border-bottom: none;
  display:wrap
}

.monthly-wrapper:first-of-type {
  border-radius: 16px 16px 0 0;
  border-top: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  border-bottom: none;
}

.monthly-wrapper:first-of-type .monthly-points{
  background-color: #f0b002
}

.monthly-wrapper:last-of-type {
  border-radius: 0 0 16px 16px;
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;
}

.monthly-wrapper .player-single {
  width: 60px;
}


table {
  font-size: 14px;
  height: 200px;
  border-spacing: 0;
  width: 100%;
  text-align: left;
  scrollbar-width: none;
}

.table-wrapper{
border: 1px solid #dadada;
border-radius: 8px;
overflow: scroll;
scrollbar-width: none;
}

.table-wrapper::-webkit-scrollbar { 
  display: none;
}


.bold {
  font-weight: 600;
}

.semibold {
  font-weight: 500;
}

.purple {
  color: #5e48e0
}

th, td {
  padding: 10px;
  border-bottom: 1px solid #dadada; 
}


tr:last-child>td {
  border-bottom:none;
}

th {
  position: sticky;
  top: 0;
  background: #fafafa;
  font-weight: 600;
  vertical-align: bottom;
}

td {
background: #fff;
}

th td:first-child {
  border-top-left-radius: 8px;
}

td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  min-width: 60px;
}


td.content-width, .content-width {
  min-width: max-content;
}

th:first-child {
  border-top-left-radius: 8px;
  position: sticky;
  left: 0;
  z-index: 2;
  min-width: max-content;
}

th:last-child {
  border-top-right-radius: 8px;
}



.italic {
  font-style: italic;
  font-size: 12px;
}

.center-content {
  display: flex;
  justify-content: center;
}

.logo {
  max-width: 120px;
}

.shield {
  max-width: 30px;
}

.mainNav {
  background-color: #fafafa;
  min-width: 300px;
  max-width: 310px;
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  padding: 30px;
  z-index: 1;
  box-sizing: border-box;

}

.topBar {
  top: 0;
  position: sticky;
  z-index: 1;
  margin-top: 10px;
  
}

.content {
  overflow-x: hidden;
}

.hidden {
  display: none !important;
}


.menuWrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  gap: 10px 20px;
  flex-wrap:nowrap;
  overflow-x: scroll;
  scrollbar-width: none !Important;
  border-bottom: 1px solid #dadada;
  margin-bottom:10px;

}

.menuWrapper button {
  background: none;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  color:#262626;
}

.menuWrapper button:active, .menuWrapper button:hover {
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #1F2FEE ;
  color:#1F2FEE;
}


.middleBlock input, select {
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 8px;
  background: #fff;
}

.middleBlock input[type=checkbox], select {
  width: auto;
}

button, .middleBlock input[type=submit]  {
  border-radius: 16px;
  border: 1px solid #1F2FEE;
  background: #1F2FEE;
  color: #fff;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 16px;
  flex:none;  
}

button:active, button:hover, .middleBlock input[type=submit]:active,.middleBlock input[type=submit]:hover   {
  background: #fff;
  color: #1F2FEE;
  cursor: pointer;
}

button.filter{
  color:#262626;
  background: #fff;
  padding: 0;
  border: 0;
}

button.filter:hover{
  color: #1F2FEE;
  cursor: pointer;
}

.middleBlock{  
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;  
}

.formBox {
  display:block;
  border: 1px solid #dadada;
  border-radius: 16px;
  padding: 20px;
}

.block {
  display: block;
}

.inline-block{
  display: inline-block;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.flex-center{
  justify-content: center;
}

.flex-end{
  justify-content: flex-end;
}

.space-evenly {
  justify-content: space-evenly;
}

.g-5 { gap: 5px} 
.g-10 { gap: 10px} 
.g-20 { gap: 20px} 
.g-30 { gap: 30px}

.h-100 {height: 100%}
.h-300px {height: 300px;}

.vh-50 {height: 50vh}
.vh-60 {height: 60vh}
.vh-70 {height: 70vh}
.vh-80 {height: 80vh}
.vh-90 {height: 90vh}
.vh-100 {height: 100vh}

.max-vh-40 {max-height: 40vh}
.max-vh-80 {max-height: 80vh}
.max-vh-70 {max-height: 70vh}

.w-50 {width: 50px;}
.w-80 {width: 80px;}
.w-100 {width: 100px;}
.w-120 {width: 120px;}
.w-200 {width: 200px;}
.w-300 {width: 300px;}
.w-400 {width: 400px;}

.minw-80 {min-width: 80px;}
.minw-100px {min-width: 100px;}
.minw-300 {min-width: 300px;}
.minw-400 {min-width: 400px;}
.minw-600 {min-width: 600px;}

.maxw-100 {max-width: 100%;}

.maxw-300 {max-width: 300px}
.maxw-200 {max-width: 200px}
.maxw-400 {max-width: 400px}
.maxw-500 {max-width: 500px}
.maxw-800 {max-width: 800px}

.maxw-100px {max-width: 100px}

.br-16 {border-radius: 16px;}
.br-8 {border-radius: 8px;}

.text-right {text-align: right;}
.text-left {text-align: left;}
.text-center{text-align: center;}

.max-content {
  max-width: max-content;
}

.center-margin{
  margin: 0 auto;
}
.menuButtons button {
  text-align: left;
  color:#262626;
  background: none;
  border: none;
  font-weight: 500;
  font-size: 18px;
  padding: 5px;
}

.menuButtons button:hover {
  background-color: #f2f0ff;
  color: #1F2FEE;
  border-radius: 8px;
  cursor: pointer;
}

.filter-container{
  max-width: 200px;
  padding: 10px;
  border: 1px solid #dadada;
  border-radius: 8px;
  position: absolute;
  right: 30px;
  z-index: 3;
  background: #fff;
}

.filter-wrapper button {
  font-size: 12px;
  border-radius: 8px;
  margin-right: 5px;
  padding: 5px 8px;
}

.filter-wrapper button.button-off {
  background: #fff;
  color: #1F2FEE;
  border: 1px solid #1F2FEE;
}

.filter-wrapper button.button-on:hover {
  background: #1F2FEE;
  color: #fff;
  border: 1px solid #1F2FEE;
}

.filter-wrapper input {
  border: 1px solid #5e48e0;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  padding: 5px 10px;
  background: #fff;
}

.text-overflow {
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.shirt-icon {
  height: 40px;
}

.entry-card {
  max-width: 450px ;
  border-radius: 8px;
  width: 100%;
  min-width: 400px;
}


.player-single {
  max-width: 65px;
}

.sub-players {
  padding: 10px 0 20px 0 ;
  border-top: 2px solid #5e48e0;
  background: rgba(94, 72, 224, 0.3);
  border-radius: 0 0 16px 16px;
}

.left-mini-card {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background: #262626;
  border-radius: 4px 0 0 4px ;
  padding: 0 2px 0 2px;
  flex-grow: 1;
}

.right-mini-card {
  font-weight: 700;
  font-size: 12px;
  padding: 0;
  border-radius: 0 4px 4px 0;
  background: #fafafa;
  flex-grow: 1;

}

.player_element_name {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background: #262626;
  border-radius: 4px 4px 0 0 ;
  padding: 0 2px 0 2px;
}

.player_element_points {
  font-weight: 700;
  font-size: 12px;
  padding: 0;
  border-radius: 0 0 4px 4px;
  background: #fafafa;
}

.player_element_points-sub {
  font-weight: 700;
  font-size: 12px;
  padding: 0;
  border-radius: 0 0 4px 4px;
  background: #fafafa;
  color: #B60A29
}

.player_element_middle {
  font-weight: 700;
  font-size: 12px;
  padding: 0;
  border-bottom: 1px solid #262626;
  background: #fafafa;
  
}
.player_element_bottom {
  font-weight: 700;
  font-size: 12px;
  padding: 0;
  border-radius: 0 0 4px 4px;
  background: rgba(94, 72, 224, 0.5);
  color: #fff;
  margin-bottom: 3px;
  
}
.player_element_available {
  font-weight: 700;
  font-size: 11px;
  padding: 0;
  border-radius: 4px;
  border: 1px solid rgb(94, 72, 224);
  background: #5e48e0;
  color: #fafafa;
  
}
.player_element_owned {
  font-weight: 800;
  font-size: 11px;
  padding: 0;
  border-radius: 4px;
  border: 1px solid #fafafa;
  background: #fafafa;
  color: #262626
}

.live-fixture_modal {
  border-radius: 8px;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  margin: 0 auto;  
  z-index: 2;
  max-width: 500px;
  overflow: hidden;
}

.modal-close {
  position:absolute;
  top:0;
  right: 0;
  font-size: 20px;
  height: 20px;
  width: 20px;
  padding: 5px;
  background-color: #5e48e0;
  color: #fff;
  text-align: center;
  
}

.live-fixture_title {
  height: 30px;
  display: flex;
  padding-left: 10px;
  gap: 10px;
  border-bottom: 1px solid #262626;
  color: #fff;
  background-color: #262626;
  
}

.live-fixture_details {
  padding: 10px;
  line-height: 24px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-radius: 0 0 8px 8px;
  background: #fafafa;
  
}

.live-fixture_name {
  display: inline-block;
  align-self: center;
  font-weight: 600;
}

.live-fixture_status {
  display: inline-block;
  align-self: center;
  font-size: 12px;
  padding: 1px 5px;
  border: 1px solid #fff;
  border-radius: 4px;
  
}

.planner-entry {
  font-size: 10px;
  padding: 1px 5px;
  border: 1px solid #262626;
  border-radius: 4px;
  align-self: flex-start;

}

.planner-available {
  font-size: 10px;
  padding: 1px 5px;
  border: 1px solid #1f2fee;
  border-radius: 4px;
  align-self: flex-start;
  background: #1f2fee;
  color: #fff;
}

.gw-status-wrapper {
  padding: 2px 10px;
  border-radius: 16px;
  text-align:center;
  font-size: 12px
}

.status-preseason, .status-finished {
  background: #B60A29;
  color: #fff;

}

.status-live {
  background: #219653;
  color: #fff;
}


.live-header {
  display:flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
  color: #fff
}


.rostered {
  font-size: 11px;
  font-weight:600;
  padding: 1px 5px;
  border: 1px solid #5e48e0;
  background: #5e48e0;
  color: #fff;
  border-radius: 4px;
  width: max-content;
}

.team-odds-holder {
  display: flex;
  gap: 5px;
  flex-basis: 50%;
  flex-wrap: wrap;
  justify-content: end;
}

.team-odds {
  font-size: 11px;
  font-weight:700;
  padding: 1px 5px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 4px;
  width: max-content;
}

.DEF {
  font-size: 10px;
  padding: 1px 5px;
  background: #1f2fee;
  color: white;
  border-radius: 4px;
  width: 30px;
  display: block;
  text-align: center;
}

.GKP {
  font-size: 10px;
  padding: 1px 5px;
  background: #5e48e0;
  color: white;
  border-radius: 4px;
  width: 30px;
  display: block;
  text-align: center;
}

.MID {
  font-size: 10px;
  padding: 1px 5px;
  background: #215b68;
  color: white;
  border-radius: 4px;
  width: 30px;
  display: block;
  text-align: center;
}

.FWD {
  font-size: 10px;
  padding: 1px 5px;
  background: #9b4707;
  color: white;
  border-radius: 4px;
  width: 30px;
  display: block;
  text-align: center;
}

.fixture {
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 4px;
  width: 100%;
  display:block;
  text-align: center;
  display: block;
}

.h2h-table .W {
  padding: 3px;
  background: #08a600;
  color: #fff;
  border-radius: 50%;
  font-size: 8px;
  width: 10px;
  height: 10px;
  text-align: center;
  font-weight: bold;
}

.h2h-table .L {
  padding: 3px;
  background: #a00000;
  color: #fff;
  border-radius: 50%;
  font-size: 8px;
  width: 10px;
  height: 10px;
  text-align: center;
  font-weight: bold;
}

.h2h-table .D {
  padding: 3px;
  background: #dadada;
  color: #262626;
  border-radius: 50%;
  font-size: 8px;
  width: 10px;
  height: 10px;
  text-align: center;
  font-weight: bold;
}
 
.h2h-table th, td {
  min-width: 0px ;
 }

 .h2h-table td:first-child {
  min-width: max-content;
 }

 .h2h-table .material-symbols-outlined {
  margin-right: 0;
 }


.lose {
  background: #a00000;
  padding: 2px;
  color: #ffffff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  font-size: 8px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}

.win {
  background: #08a600;
  padding: 2px;
  color: #ffffff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  font-size: 8px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}

.draw {
  background: #dadada;
  padding: 2px;
  color: #262626;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  font-size: 8px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}

.up-rank {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.down-rank {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f00;
}

.rank {
  font-size: 16px;
}

.tablechart-toggle button{
  border-radius: 0;
  padding: 5px 15px;
  text-align: center;
  color: #1F2FEE;
  background-color: transparent;
  border: none;

}

.tablechart-toggle button.active{
  color: #fff ;
  background-color: #1F2FEE ;
}

.tablechart-toggle button:first-of-type{
  border: 1px solid #1F2FEE;
  border-radius: 8px 0 0 8px;
}

.tablechart-toggle button:last-of-type {
  border: 1px solid #1F2FEE;
  border-radius: 0 8px 8px 0;
}

.download-data {
  color: #5e48e0;
  text-decoration: none;
  background-color: #fff;
}

.download-link {
  color: #5e48e0;
  text-decoration: none;
}

.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right:5px
}

.global-card {
  min-width: 500px;
  max-width: 600px;
}


.history-menu {
  display: flex;
  gap: 10px;
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #dadada
}

.history-menu select{
  padding: 10px;
  margin-left: 5px;
  border: none;
}

.mitch-draft-rank {
  overflow-y: scroll;
  min-width: 350px;
}

.mitch-draft-rank button {
  padding: 0 5px;
}

.mitch-draft-rank .minus button, .player-single-buttons button {
  padding: 0 5px;
  background:#fff;
  color: #1F2FEE;
  border-color: #1F2FEE;
}

.mitch-draft-rank select {
  font-size: 12px;
  padding: 3px
}

.gw-toggle button.active {
  color: #fff;
  background-color: #1F2FEE;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  
  -moz-appearance: textfield;
  width: 30px;
}

.position-entry {
  font-size: 10px;
  padding: 1px 5px;
  border: 1px solid #262626;
  border-radius: 4px;
  width: 30px;
  text-align: center;
}

.arrow-symbol {
  font-size: 24px;
  color: 20px solid #5e48e0; /* This color will be the color of your arrow */
}

.custom-tooltip {
  border: 1px solid #dadada;
  background: #fff;
  padding: 10px;
}

.cell-block span{
  display: block;
  font-size: 12px;
}

.skeleton-container {
  font-family: Arial, sans-serif;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}

.skeleton-header,
.skeleton-paragraph,
.skeleton-cell,
.skeleton-block {
  background: linear-gradient(
    90deg,
    #fafafa 25%, 
    #f0f0f0 50%, 
    #fafafa 75%
  );
  background-size: 200% 100%;
  animation: shine 3s infinite;
}
.skeleton-header {
  height: 24px;
  width: 50%;
  margin-bottom: 20px;
  border-radius: 8px;
}

.skeleton-paragraph {
  height: 30px;
  width: 90%;
  margin-bottom: 20px;
  border-radius: 8px;
}

.skeleton-table {
  margin-bottom: 20px;
}

.skeleton-row {
  display: flex;
  margin-bottom: 10px;
}

.skeleton-cell {
  flex: 1;
  height: 20px;
  margin-right: 10px;
  border-radius: 8px;
}

.skeleton-cell:last-child {
  margin-right: 0;
}


.skeleton-block {
  height: 200px;
  width: 100%;
  border-radius: 4px;
}

.refresh-button {
  padding: 3px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 14px;
}

.fdr-cell {
  padding: 0px;
}

.fdr-cell .fdr-span {
  padding: 10px;
  height: 100%
}

.risk-buttons .unselected {
 background: white;
 color: rgb(94, 72, 224)
}

.risk-buttons button:hover {
  background:#1F2FEE;
  color: white;
}

.blur {
  filter: blur(5px);
  pointer-events: none;
  opacity: 0.7;
}

.temp-remove {
  position: relative;
}

.temp-remove::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100%);
  height: calc(100%);
  background-color: rgba(128, 128, 128, 0.7);
  pointer-events: none;
  border-radius: 4px;
  z-index: 1;

}

.cancel-transaction {
  font-size: 12px;
  padding: 2px 1px;
  border-radius: 8px;
  z-index: 999;
  width: 70px;
  position: absolute;
  right: 0;

}

.cancel-transaction-button {
  width: 16px;
  height: 16px;
  position: absolute;
  top:0px;
  right: -15px
}

.add-transaction-button {
  width: 20px;
  height: 20px;
}

.player-shirt-container {
  position: relative; /* Establishes a positioning context for the remove button */
  display: inline-block; /* Adjusts the container to fit the content */
}

.player-shirt-container img {
  display: block; /* Ensures images are block-level within the container */
}

.relative {
  position: relative;
}

.placeholder-image {
  width: 4px;
  height: 24px;
  display: inline-block;
  opacity: 0; /* Makes the placeholder invisible */
}

.player-name img {
  height: 30px;
}